<template>
  <CRow>
    <CCol>
      <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow">
        <CCard>
          <CCardHeader><font-awesome-icon icon="pencil-ruler" fixed-width /> <strong>報價說明</strong> </CCardHeader>
          <CCardBody>
            <editor v-model="content" api-key="6s293m3q9fyv0od26sl9yd6vttazj5wsyt5u35jzltnyqoku" :init="editorConfig" />
          </CCardBody>
          <CCardFooter class="text-right">
            <CButton type="button" color="primary" class="mr-1" @click="update()">確認更新</CButton>
          </CCardFooter>
        </CCard>
      </b-overlay>
    </CCol>
  </CRow>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
export default {
  name: "",
  components: {
    editor: Editor,
  },
  data: () => ({
    loading: true,
    content: "",
    editorConfig: {
      language: "zh_TW",
      height: 500,
      menubar: false,
      toolbar:
        "undo redo | forecolor backcolor fontsizeselect  | bold italic | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help",
    },
  }),
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$http.get("/quote/description").then((response) => {
        if (response.data.success) {
          this.content = response.data.result;
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
        this.loading = false;
      });
    },
    update() {
      this.loading = true;
      this.$http.post("/quote/description", { data: this.content }).then((response) => {
        if (response.data.success) {
          this.$notify({ type: "success", text: "更新成功" });
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
        this.loading = false;
      });
    },
  },
};
</script>